import * as React from 'react'
import { Link } from 'gatsby'
import IndexLayout from '../layouts'

export default function FourZeroFour() {
  return (
    <IndexLayout>
      <div className="links">
        <h1 className="sectionTitle">Seite nicht gefunden!</h1>
        <Link to="/">Zum Startseite</Link>
      </div>
    </IndexLayout>
  )
}
